import { mime } from '@/config/mime.js'
import { httpApi } from './http/httpApi'

export const uploadCall = async (fieldName, fileList, callId) => {
  const formData = new FormData()
  if (fileList.length > 0) {
    for (const file of fileList) {
      formData.append(fieldName, file, file.name)
    }
    try {
      const response = await httpApi.post(
        `/communications/calls/${callId}/communication-file`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      if (response.status === 201) {
        return response.data
      } else {
        return null
      }
    } catch (err) {
      return null
    }
  }
}

export const uploadNotice = async (
  fieldName,
  fileList,
  noticeId,
  overwrite
) => {
  const formData = new FormData()
  if (fileList.length > 0) {
    for (const file of fileList) {
      formData.append(fieldName, file, file.name)
    }
    try {
      const url = `/communications/notices/${noticeId}/communication-file`
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      const response = overwrite
        ? await httpApi.put(url, formData, options)
        : await httpApi.post(url, formData, options)
      if (response.status === 201) {
        return response.data
      } else {
        return null
      }
    } catch (err) {
      return null
    }
  }
}

export const uploadReportFile = async (
  fieldName,
  fileList,
  noticeId,
  overwrite
) => {
  const formData = new FormData()
  if (fileList.length > 0) {
    for (const file of fileList) {
      formData.append(fieldName, file, file.name)
    }
    try {
      const url = `/communications/notices/${noticeId}/report-file`
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      const response = overwrite
        ? await httpApi.put(url, formData, options)
        : await httpApi.post(url, formData, options)
      if (response.status === 201) {
        return response.data
      } else {
        return null
      }
    } catch (err) {
      return null
    }
  }
}

export const uploadDocument = async (fieldName, fileList, callId) => {
  const formData = new FormData()
  if (fileList.length > 0) {
    for (const file of fileList) {
      formData.append(fieldName, file, file.name)
    }
    try {
      const response = await httpApi.post(
        `/documents/${callId}/file`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      if (response.status === 201) {
        return response.data
      } else {
        return null
      }
    } catch (err) {
      return null
    }
  }
}

const downloadFile = async ({ url, type }) => {
  try {
    const response = await httpApi.get(url, { responseType: 'arraybuffer' })
    return new Blob([response.data], { type })
  } catch (err) {
    console.log(err)
    return null
  }
}

export const downloadNoticeFile = async noticeId => {
  const type = 'application/pdf'
  const url = `/communications/notices/${noticeId}/communication-file`
  return downloadFile({ url, type })
}

export const downloadReportFile = async noticeId => {
  const type = 'application/pdf'
  const url = `/communications/notices/${noticeId}/report-file`
  return downloadFile({ url, type })
}

export const downloadContractFile = async (noticeId, global) => {
  const type = 'application/pdf'
  const url = global
    ? `/communications/notices/${noticeId}/communication-file`
    : `/recipients/notices/${noticeId}/contract`
  return downloadFile({ url, type })
}

export const downloadContractFileWithUserId = async (noticeId, userId) => {
  const type = 'application/pdf'
  const url = `/recipients/notices/${noticeId}/${userId}/contract`
  return downloadFile({ url, type })
}

export const downloadWithholdingFile = async withholdingId => {
  const type = 'application/pdf'
  const url = `/withholdings/${withholdingId}/file`
  return downloadFile({ url, type })
}
export const downloadPayrollFile = async payrollId => {
  const type = 'application/pdf'
  const url = `/payrolls/${payrollId}/file`
  return downloadFile({ url, type })
}

export const downloadDocumentFile = async (documentId, extension) => {
  const parsedExt = extension.charAt(0) === '.' ? extension.slice(1) : extension
  const type = mime[parsedExt]
  if (!type) throw new Error('Tipo no encontrado')
  const url = `/documents/${documentId}/file`
  return downloadFile({ url, type })
}

export const createFileUrl = file => {
  console.log('createFileUrl')
  try {
    if (window.navigator.msSaveOrOpenBlob) {
      // IE11
      window.navigator.msSaveOrOpenBlob(file)
    } else {
      //if (
      //   navigator.platform &&
      //   /iPad|iPhone|iPod/.test(navigator.platform)
      // ) {
      //   // iOS
      //   console.log('ios')
      //   var reader = new FileReader()
      //   reader.onload = function () {
      //     window.location.href = reader.result
      //   }
      //   reader.readAsDataURL(file)
      // } else {
      // Google Chrome, Firefox, ....
      return (window.URL || window.webkitURL).createObjectURL(file)
    }
  } catch (err) {
    console.log(err)
  }
}

const actionFileOpening = ({ file, name }) => {
  if (file) {
    const pdfUrl = createFileUrl(file)
    if (pdfUrl) {
      // if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      //   const newWindow = window.open()
      //   newWindow.location = pdfUrl
      // } else {
      const link = document.createElement('a')
      link.href = pdfUrl
      link.target = '_blank'
      link.download = name || 'documento.pdf'
      document.body.appendChild(link)
      link.click()
      setTimeout(function () {
        window.URL.revokeObjectURL(pdfUrl)
      }, 100)
      link.remove()
    }
    // }
  } else {
    alert('No file available')
    throw new Error('No file available')
  }
}

export const openContract = async (userId, noticeId) => {
  const file = await downloadContractFileWithUserId(noticeId, userId)
  actionFileOpening({ file, name: 'contrato.pdf' })
}

export const openFile = async ({
  name,
  entity,
  entityID,
  extension,
  global,
}) => {
  const downloadMethods = {
    notice: () => downloadNoticeFile(entityID),
    call: () => downloadNoticeFile(entityID),
    report: () => downloadReportFile(entityID),
    contract: () => downloadContractFile(entityID, global),
    withholding: () => downloadWithholdingFile(entityID),
    payroll: () => downloadPayrollFile(entityID),
    document: () => downloadDocumentFile(entityID, extension),
  }
  if (!downloadMethods[entity]) {
    throw new Error(`Entity ${entity} not supported`)
  }

  const file = await downloadMethods[entity]()

  actionFileOpening({ file, name })
}
