<template>
  <b-card no-body class="mb-3">
    <b-card-title class="mt-2">
      <b-container fluid>
        <b-row align-h="between">
          <b-col cols="12">
            <h3>{{ call.name }}</h3>
          </b-col>
        </b-row>
      </b-container>
    </b-card-title>
    <b-card-sub-title>
      <b-container fluid>
        <b-row align-h="between">
          <b-col md="8" sm="12">
            <span>{{ call.date | toDateTime }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card-sub-title>
    <b-card-body>
      <b-row>
        <b-col md="8" xs="12">
          <p>{{ call.description }}</p>
        </b-col>
        <b-col md="4" xs="12">
          <b-button
            block
            :variant="call.hasCommunicationFile ? 'primary' : ''"
            size="lg"
            :disabled="!call.hasCommunicationFile"
            @click="openPdf('call')"
          >
            {{ call.hasCommunicationFile ? 'Ver convocatoria' : 'Sin fichero' }}
          </b-button>
          <b-button
            v-if="call.hasReportFile"
            block
            :variant="call.hasReportFile ? 'primary' : ''"
            size="lg"
            @click="openPdf('report')"
          >
            {{ call.hasReportFile ? `Ver acta` : 'Sin acta' }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-footer v-if="call.seen || call.confirmationRequested">
      <b-row>
        <template v-if="call.confirmationRequested">
          <b-col md="6" xs="12">
            <b-button
              v-if="!call.confirmed || (call.confirmed && call.accepted)"
              class="mr-3"
              block
              size="lg"
              :disabled="call.confirmed"
              :variant="
                call.confirmed ? (call.accepted ? 'success' : '') : 'success'
              "
              @click="processAcceptCall()"
            >
              {{
                call.confirmed
                  ? call.accepted
                    ? 'Asistencia confirmada'
                    : ''
                  : 'Asistiré'
              }}
            </b-button>
          </b-col>
          <b-col md="6" xs="12">
            <b-button
              v-if="!call.confirmed || (call.confirmed && !call.accepted)"
              size="lg"
              block
              :disabled="call.confirmed"
              :variant="
                call.confirmed ? (!call.accepted ? 'danger' : '') : 'danger'
              "
              @click="processRejectCall()"
            >
              {{
                call.confirmed
                  ? !call.accepted
                    ? 'Asistencia rechazada'
                    : ''
                  : 'No asistiré'
              }}
            </b-button>
          </b-col>
          <b-col v-if="call.confirmed" cols="12">
            <p class="card-footer-notes">
              {{ call.accepted ? 'Aceptado' : 'Rechazado' }} el
              {{ call.confirmationDate | toDateTime }}
            </p>
          </b-col>
        </template>
        <b-col v-if="call.seen" cols="12">
          <p class="mt-2 mb-0 card-footer-notes">
            {{ call.seen ? 'Visto' : 'No visto' }} el
            {{ call.seenDate | toDateTime }}
          </p>
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>
<script>
import { mapActions } from 'vuex'
import { openFile } from '@/services/filesService.js'
import { errorOnFileLoad } from '@/services/messageService'

export default {
  name: 'CallCard',
  props: {
    call: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pdfUrl: '',
    }
  },
  methods: {
    ...mapActions('notices', ['acceptNotice', 'rejectNotice', 'seenNotice']),
    async openPdf(entity) {
      try {
        const fileProperties = {
          entity,
          entityID: this.call.noticeId,
          name: this.call.name,
        }
        await openFile(fileProperties)
        if (entity === 'call') {
          await this.seenNotice(this.call.noticeId)
          window.location.reload()
        }
      } catch (error) {
        errorOnFileLoad({ context: this, error })
      }
    },
    processAcceptCall() {
      this.acceptNotice(this.call.noticeId).then(() => {
        window.location.reload() // @TODO Figure out the computed property not reloading on state update
      })
    },
    processRejectCall() {
      this.rejectNotice(this.call.noticeId).then(() => {
        window.location.reload() // @TODO Figure out the computed property not reloading on state update
      })
    },
  },
}
</script>
<style>
.card-footer-notes {
  margin-bottom: 0;
  margin-top: 0.25em;
}
</style>
