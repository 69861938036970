<template>
  <b-card no-body class="mb-3">
    <b-card-title class="mt-2">
      <b-container fluid>
        <b-row align-h="between">
          <b-col cols="12">
            <h3>{{ notice.name }}</h3>
          </b-col>
        </b-row>
      </b-container>
    </b-card-title>
    <b-card-sub-title>
      <b-container fluid>
        <b-row align-h="between">
          <b-col md="8" sm="12">
            <span>{{ notice.date | toDateTime }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card-sub-title>
    <b-card-body>
      <b-row>
        <b-col md="8" xs="12">
          <p>{{ notice.description }}</p>
        </b-col>
        <b-col md="4" xs="12">
          <b-button
            block
            :variant="notice.hasCommunicationFile ? 'primary' : ''"
            size="lg"
            :disabled="!notice.hasCommunicationFile"
            @click="openPdf()"
          >
            {{
              notice.hasCommunicationFile ? 'Ver comunicación' : 'Sin fichero'
            }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <b-card-footer v-if="notice.seen || notice.confirmationRequested">
      <b-row>
        <template v-if="notice.confirmationRequested">
          <b-col md="6" xs="12">
            <b-button
              v-if="!notice.confirmed || (notice.confirmed && notice.accepted)"
              block
              size="lg"
              :disabled="notice.confirmed"
              :variant="
                notice.confirmed
                  ? notice.accepted
                    ? 'success'
                    : ''
                  : 'success'
              "
              @click="processAccept()"
            >
              {{
                notice.confirmed
                  ? notice.accepted
                    ? 'Aceptada'
                    : ''
                  : 'Acepto'
              }}
            </b-button>
          </b-col>
          <b-col md="6" xs="12">
            <b-button
              v-if="!notice.confirmed || (notice.confirmed && !notice.accepted)"
              size="lg"
              block
              :disabled="notice.confirmed"
              :variant="
                notice.confirmed ? (!notice.accepted ? 'danger' : '') : 'danger'
              "
              @click="processReject()"
            >
              {{
                notice.confirmed
                  ? !notice.accepted
                    ? 'No aceptada'
                    : ''
                  : 'No acepto'
              }}
            </b-button>
          </b-col>
          <b-col v-if="notice.confirmed" cols="12">
            <p class="mt-2 mb-0 card-footer-notes">
              {{ notice.accepted ? 'Aceptado' : 'Rechazado' }} el
              {{ notice.confirmationDate | toDateTime }}
            </p>
          </b-col>
        </template>
        <b-col v-if="notice.seen" cols="12">
          <p class="mt-2 mb-0 card-footer-notes">
            {{ notice.seen ? 'Visto' : 'No visto' }} el
            {{ notice.seenDate | toDateTime }}
          </p>
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>
<script>
import { mapActions } from 'vuex'
import { openFile } from '@/services/filesService.js'
import { errorOnFileLoad } from '@/services/messageService'

export default {
  name: 'NoticeCard',
  props: {
    notice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pdfUrl: '',
    }
  },
  methods: {
    ...mapActions('notices', ['acceptNotice', 'rejectNotice', 'seenNotice']),
    async openPdf() {
      try {
        const fileProperties = {
          entity: 'notice',
          entityID: this.notice.noticeId,
          name: this.notice.name,
        }
        await openFile(fileProperties)
        await this.seenNotice(this.notice.noticeId)
        window.location.reload()
      } catch (error) {
        errorOnFileLoad({ context: this, error })
      }
    },
    processAccept() {
      this.acceptNotice(this.notice.noticeId).then(() => {
        window.location.reload() // @TODO Figure out the computed property not reloading on state update
      })
    },
    processReject() {
      this.rejectNotice(this.notice.noticeId).then(() => {
        window.location.reload() // @TODO Figure out the computed property not reloading on state update
      })
    },
  },
}
</script>
<style>
.card-footer-notes {
  margin-bottom: 0;
  margin-top: 0.25em;
}
</style>
